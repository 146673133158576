import React, { useState, useContext } from "react";
import { Link } from "gatsby";
import Container from "./Container";
import InputField from "./InputField";
import axios from "axios";
import { LoaderContext } from "./Loader";
import { ToastContext } from "./Toast";
import { Helmet } from "react-helmet";
import validateForgetPassword from "../util/validations/validateForgetPassword";

const ResendVerification = () => {
  const [email, setEmail] = useState("");
  const [validationErrors, setErrors] = useState({});
  const loader = useContext(LoaderContext);
  const toast = useContext(ToastContext);

  const handleResendVerification = () => {
    const { isValid, errors } = validateForgetPassword({ email: email });
    if (isValid) {
      loader.showLoader();
      axios
        .post(`${process.env.BASE_API_URL}/send-verification-email`, {
          email: email,
        })
        .then(function (response) {
          loader.hideLoader();
          toast.showToast(response.data.message || "Please check you email.", "success");
        })
        .catch(function (error) {
          loader.hideLoader();
          toast.showToast(
            error?.response?.data?.message || "Something went wrong!",
            "error"
          );
        });
    } else {
      setErrors(errors);
    }
  };

  const handleUserChange = ({ target }) => {
    const clonedErrors = { ...validationErrors };
    clonedErrors["email"] = "";
    setEmail(target.value);
    setErrors(clonedErrors);
  };

  return (
    <Container>
      <Helmet>
        <title>Resend verification</title>
      </Helmet>
      <div className="signup-container">
        <div className="signup-form">
          <p className="suport-text">
            Enter your email address and we will send a
            verification email if it is not already verified.
          </p>
          <InputField
            placeholder="Email"
            type="text"
            value={email}
            required
            name="email"
            onChange={handleUserChange}
            error={validationErrors?.email}
          />
          <div className="signup-button-container">
            <button onClick={handleResendVerification} className="signup-button">
              Resend verification
            </button>
          </div>
          <p className="login-link horizontal">
            <span>
              <Link to="/app/login">Back to sign in</Link>
            </span>
            <span>
              <Link to="/app/signup">Sign up</Link>
            </span>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default ResendVerification;
