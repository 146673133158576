import React, { useState, useContext } from "react";
import { navigate } from "gatsby";
import Container from "./Container";
import InputField from "./InputField";
import axios from "axios";
import { LoaderContext } from "./Loader";
import { ToastContext } from "./Toast";
import { Helmet } from "react-helmet";
import validateForgetPassword from "../util/validations/validateForgetPassword";
import { encodeUri } from "../util/utils";
import { Link } from "gatsby";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [validationErrors, setErrors] = useState({});
  const loader = useContext(LoaderContext);
  const toast = useContext(ToastContext);

  const handleForgotPassword = () => {
    const { isValid, errors } = validateForgetPassword({ email: email });
    if (isValid) {
      loader.showLoader();
      axios
        .post(`${process.env.BASE_API_URL}/forgot-password`, { email: email })
        .then(function (response) {
          loader.hideLoader();
          toast.showToast("We have emailed your password reset link.", "success");
          navigate(`/app/reset-password?email=${encodeUri(response.data.data.email)}`);
        })
        .catch(function (error) {
          loader.hideLoader();
          toast.showToast(
            error?.response?.data?.message || "We were unable send a password reset email. Please contact Ivory Consulting.",
            "error"
          );
        });
    } else {
      setErrors(errors);
    }
  };

  const handleUserChange = ({ target }) => {
    const clonedErrors = { ...validationErrors };
    clonedErrors["email"] = "";
    setEmail(target.value);
    setErrors(clonedErrors);
  };

  return (
    <Container>
      <Helmet>
        <title>Forgot password</title>
      </Helmet>
      <div className="signup-container">
        <div className="signup-form">
          <p className="suport-text">
            Enter your email address and we will send you a link
            to reset your password.
          </p>
          <InputField
            placeholder="Email"
            type="text"
            value={email}
            required
            name="email"
            onChange={handleUserChange}
            error={validationErrors?.email}
            autocomplete="email"
          />
          <div className="signup-button-container">
            <button onClick={handleForgotPassword} className="signup-button">
              Reset your password
            </button>
          </div>
          <div>
              <Link to="/account-help">Need other account help?</Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
