import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Resources from "../../../layouts/Resources";

const Downloads = (props) => {
  const data = useStaticQuery(graphql`
    {
      bannerImage: file(relativePath: { eq: "sub_page_heros/Oracle-2_unfiltered.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mdx: mdx(frontmatter: { slug: { eq: "/resources/downloads" } }) {
        frontmatter {
          title
          slug
          inlineImages {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        body
      }
      resources: allFile(filter: { dir: { regex: "/.*resources.*/" } }) {
        edges {
          node {
            name
            prettySize
            dir
            extension
            relativePath
            publicURL
          }
        }
      }
    }
  `);
  const { bannerImage } = data;
  return (
    <Resources
      pageContext={props.pageContext}
      data={data}
      image={bannerImage.childImageSharp}
    >
      <div
        name="SuperTRUMP Desktop 22.2"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="SuperTRUMP Software Downloads"
        fileName="Software_Downloads/SuperTRUMP_Desktop_Setup_22-2-11694_(7543)_2022-12-05.exe"
        prettySize="72 MB"
        extension="EXE"
      />
      ,
      <div
        name="SuperTRUMP Desktop Classic 15.2"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="SuperTRUMP Software Downloads"
        fileName="Software_Downloads/SuperTRUMP_Desktop_Setup_15-2-11694_(7543)_2022-12-05.exe"
        prettySize="6 MB"
        extension="EXE"
      />
      ,
      <div
        name="Portfolio Intelligence 12.1"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Portfolio Intelligence Software Downloads"
        fileName="Software_Downloads/Portfolio_Setup_64_12-10-11186_(7615)_2023-02-22.msi"
        prettySize="44 MB"
        extension="MSI"
      />
      ,
      <div
        name="SuperTRUMP Desktop Installation Guide"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Installation Guides"
        fileName="Installation_Guides/SuperTRUMP-Desktop-Installation-Guide.pdf"
        prettySize="472 kB"
        extension="PDF"
      />
      ,
      <div
        name="SuperTRUMP for Salesforce Setup and Administration Guide"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Installation Guides"
        fileName="Getting_Started_and_Installation_Guides/STforSalesforceInstallationGuide.pdf"
        prettySize="1.77 MB"
        extension="PDF"
      />
      ,
      <div
        name="SuperTRUMP Web Installation Guide"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Installation Guides"
        fileName="Installation_Guides/SuperTRUMP-10-Web-Installation-Guide.pdf"
        prettySize="2.10 MB"
        extension="PDF"
      />
      ,
      <div
        name="SuperTRUMP Web Reporter Installation Guide"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Installation Guides"
        fileName="Installation_Guides/SuperTRUMP-10-Web-Reporter-Installation-Guide.pdf"
        prettySize="2.10 MB"
        extension="PDF"
      />
      ,
      <div
        name="Portfolio Intelligence Installation Guide"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Installation Guides"
        fileName="Installation_Guides/SuperTRUMP-Portfolio-Intelligence-Installation-Guide.pdf"
        prettySize="405 kB"
        extension="PDF"
      />
      ,
      <div
        name="22.2/15.2 Release Notes"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Release Notes"
        fileName="Release_Notes/SuperTRUMP_22-2_15-2_ReleaseNotes.pdf"
        prettySize="28 kB"
        extension="PDF"
      />
      ,
      <div
        name="21.1/15.1 Release Notes"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Release Notes"
        fileName="Release_Notes/SuperTRUMP15_1_22_1ReleaseNotes.pdf"
        prettySize="30 kB"
        extension="PDF"
      />
      ,
      <div
        name="21.1/14.1 Release Notes"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Release Notes"
        fileName="Release_Notes/SuperTRUMP14_1_21_1ReleaseNotes.pdf"
        prettySize="42 kB"
        extension="PDF"
      />
      ,
      <div
        name="12.10 Release Notes"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Release Notes"
        fileName="Release_Notes/SuperTRUMP1210ReleaseNotes.pdf"
        prettySize="55 kB"
        extension="PDF"
      />
      ,
      <div
        name="12.00 Release Notes"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Release Notes"
        fileName="Release_Notes/SuperTRUMP1200ReleaseNotes.pdf"
        prettySize="44 kB"
        extension="PDF"
      />
      ,
      <div
        name="SuperTRUMP Desktop Getting Started Guide"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Reference Guides"
        fileName="Getting_Started_and_Installation_Guides/SuperTRUMP15DesktopGettingStartedGuide.pdf"
        prettySize="629 kB"
        extension="PDF"
      />
      ,
      <div
        name="SuperTRUMP Desktop 2021 Interface Getting Started Guide"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Reference Guides"
        fileName="Getting_Started_and_Installation_Guides/SuperTRUMP21DesktopGettingStartedGuide.pdf"
        prettySize="629 kB"
        extension="PDF"
      />
      ,
      <div
        name="SuperTRUMP for Salesforce Getting Started Guide"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Reference Guides"
        fileName="Getting_Started_and_Installation_Guides/SuperTRUMPforSalesforceGettingStarted.pdf"
        prettySize="858 kB"
        extension="PDF"
      />
      ,
      <div
        name="SuperTRUMP Web Getting Started Guide"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Reference Guides"
        fileName="Getting_Started_and_Installation_Guides/SuperTRUMPfortheWebGettingStarted.pdf"
        prettySize="858 kB"
        extension="PDF"
      />
      ,
      <div
        name="Portfolio Intelligence Getting Started Guide"
        path="DownloadsPlaceholderDoNotMoveOrRename"
        category="Reference Guides"
        fileName="Reference_Guides/Portfolio-Intelligence-Getting-Started-Guide.pdf"
        prettySize="1.40 MB"
        extension="PDF"
      />
    </Resources>
  );
};

export default Downloads;
